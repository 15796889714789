<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      :item_no="item_no"
      page_title="dashboard"
    />

    <v-card color="#f5f6f9" class="mx-3 my-5" style="border-radius: 16px">
      <div style="width: 100%" class="pl-1 pr-1">
        <v-row justify="space-between" class="ma-0 pa-0">
          <v-col cols="6" lg="6" md="6" class="mt-5">
            <p
              style="
                color: #424242;
                font-weight: bold;
                font-size: 15px;
                padding-left: 12px;
              "
            >
              {{ $t("lastupdate") }} {{ currentdate }}
            </p>
          </v-col>
          <v-col cols="6" lg="6" md="6" style="text-align: right">
            <v-row style="text-align: left" class="ml-25">
              <v-spacer></v-spacer>
              <v-col cols="4" lg="3" md="3">
                <label
                  for="viewdatefrom"
                  style="font-size: 15px; color: #424242; font-weight: bold"
                  >{{ $t("viewdatefrom") }}</label
                >
                <v-menu
                  v-model="frommenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      placeholder="yyyy/mm/dd"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startdate"
                    :min="minDate"
                    @change="StartViewDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col lg="3" cols="4" md="3">
                <label
                  for="to"
                  style="
                    font-size: 15px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("to") }}</label
                >
                <v-menu
                  id="date_"
                  v-model="tomenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      placeholder="yyyy/mm/dd"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="enddate"
                    :min="minDate"
                    :allowed-dates="allowedEndDates"
                    @input="tomenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="mt-5" cols="4" lg="3" md="4">
                <v-btn
                  width="120"
                  height="40"
                  class="text-capitalize btn_hover_effect ml-5"
                  style="
                    color: #ffff;
                    border: 1px solid #a6cc39;
                    border-radius: 8px;
                    font-size: 16px;
                  "
                  color="#A6CC39"
                  @click="FilterData()"
                  >{{ $t("search") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" no-gutters>
          <v-col cols="5" class="ml-1">
            <v-row class="ma-0 pa-0" no-gutters>
              <v-col cols="12" lg="4" md="3" class="pr-2">
                <v-card
                  flat
                  class="my-custom-cardall"
                  style="background-color: transparent"
                >
                  <div
                    class="white-text justify-center"
                    style="position: absolute; left: 10%"
                  >
                    <p
                      class="white--text"
                      style="padding-top: 7px; font-size: 18px"
                    >
                      {{ $t("allusers") }}
                    </p>
                  </div>
                  <div class="white--text">
                    <p class="user-counts mb-0 mt-3">
                      {{ item.allUserCount }}
                    </p>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" lg="4" md="3" class="pr-2">
                <v-card
                  flat
                  class="my-custom-cardactive"
                  style="background-color: transparent"
                >
                  <div
                    class="white--text justify-center"
                    style="position: absolute; left: 9%"
                  >
                    <p
                      class="white--text"
                      style="padding-top: 7px; font-size: 18px"
                    >
                      {{ $t("activeuser") }}
                    </p>
                  </div>
                  <div class="white--text">
                    <p
                      class="user-countsactive mb-0 mt-3"
                      style="padding-left: 0%"
                    >
                      {{ item.activeUserCount }}
                    </p>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" lg="4" md="3">
                <v-card
                  flat
                  class="my-custom-cardinactive"
                  style="background-color: transparent"
                >
                  <div
                    class="white--text justify-center"
                    style="position: absolute; left: 9%"
                  >
                    <p
                      class="white--text"
                      style="padding-top: 7px; font-size: 18px"
                    >
                      {{ $t("inactiveuser") }}
                    </p>
                  </div>
                  <div class="white--text">
                    <p class="user-counts mb-0 mt-3">
                      {{ item.inActiveUserCount }}
                    </p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-row class="ml-1">
              <v-col cols="4" lg="4" md="4" class="pr-0">
                <v-card
                  flat
                  class="my-custom-cards"
                  style="background-color: #3c3c3c"
                >
                  <div class="totalusercount">
                    <p
                      class="white-text justify-center"
                      style="padding-top: 20px; font-size: 18px"
                    >
                      {{ $t("totalcontentviews") }}
                    </p>
                  </div>
                  <div class="white--text">
                    <p
                      style="
                        font-size: 60px;
                        text-align: center;
                        position: absolute;
                        top: 45%;
                        left: 49%;
                        transform: translate(-50%, -50%);
                      "
                    >
                      {{ item.totalContentView }}
                    </p>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="8" lg="8" md="8">
                <v-card
                  style="border-radius: 16px"
                  max-width="100%"
                  max-height="500px"
                >
                  <v-card-title class="text-center justify-center">
                    <p style="font-weight: bold; font-size: 15px">
                      {{ $t("whichcontentmostviewed") }}
                    </p>
                  </v-card-title>

                  <apexchart
                    class="dynamic-height-chart"
                    type="donut"
                    :key="chartkey"
                    :options="chartOptions_Content"
                    :series="ContentSeries"
                    :width="chartWidth"
                    :height="chartHeight"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-card
              style="border-radius: 16px"
              class="mx-auto"
              max-width="100%"
              max-height="350px"
            >
              <v-card-title class="text-center justify-center">
                <p style="font-weight: bold; font-size: 15px">
                  {{ $t("daysmostcontentviewd") }}
                </p>
              </v-card-title>
              <div id="chart" class="pl-5">
                <apexchart
                  :type="linechartOptions_Date.chart.type"
                  :width="chartWidth"
                  :key="chartkey"
                  :height="chartHeights"
                  :options="linechartOptions_Date"
                  :series="series_Date"
                ></apexchart>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-card
              style="border-radius: 16px"
              max-width="100%"
              max-height="570px"
              class="ml-5"
            >
              <v-card-title class="text-center justify-center">
                <p style="font-weight: bold; font-size: 15px">
                  {{ $t("top5employeesmostview") }}
                </p>
              </v-card-title>
              <template class="pl-10">
                <v-data-table
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  :headers="headers"
                  :items="TopFiveUserList"
                  hide-default-footer
                  :page-count="pageCount"
                  item-key="EmployeeId"
                  class="tabledata"
                >
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.No="{ index }">
                    {{ index + 1 }}
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.profilePic="{ item }">
                    <v-avatar justify="center" align="center" size="50">
                      <v-img
                        :src="
                          item.profilePic ||
                          require('../assets/dashboards 3.png')
                        "
                      ></v-img>
                    </v-avatar>
                  </template>
                </v-data-table>
              </template>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
              style="border-radius: 16px"
              max-width="1200"
              max-height="550px"
            >
              <v-card-title class="text-center justify-center">
                <p style="font-weight: bold; font-size: 15px">
                  {{ $t("statuscoursemenu") }}
                </p>
              </v-card-title>
              <div>
                <apexchart
                  v-if="!loading"
                  class="dynamic-height-charts"
                  type="donut"
                  :key="chartkeycourse"
                  :options="chartOptions_Course"
                  :series="CourseStatusSeries"
                  :width="chartWidth"
                  :height="chartHeightCourse"
                />
                <div v-else>
                  <P style="text-align: center">Loading... Please wait</P>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-card
              style="border-radius: 16px"
              max-width="1200px"
              max-height="350px"
              class="ml-5"
            >
              <v-card-title class="text-center justify-center">
                <p style="font-weight: bold; font-size: 15px">
                  {{ $t("content5mostviewed") }}
                </p>
              </v-card-title>
              <apexchart
                class="dynamic-height-chart"
                type="donut"
                :key="chartkey"
                :options="chartOptions"
                :series="CourseVideoSeries"
                :width="chartWidth"
                :height="chartHeightss"
              />
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card
              style="border-radius: 16px"
              class="mx-auto"
              max-width="100%"
              max-height="450px"
            >
              <v-card-title class="text-center justify-center">
                <p style="font-weight: bold; font-size: 15px">
                  {{ $t("departmentcontentviewers") }}
                </p>
              </v-card-title>
              <div id="chart">
                <apexchart
                  :type="chartOptionbar_Dept.chart.type"
                  :key="chartkey"
                  :options="chartOptionbar_Dept"
                  :series="series_Dept"
                  :width="chartWidth"
                  :height="chartHeightBar"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog v-model="page_loading" persistent width="300">
      <v-card color="#4FB14E" dark>
        <v-card-text class="white--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    responseData: [],
    permissionDialog: false,
    permissionMessage: "",
    pageCount: null,
    TopFiveUserList: [],
    item: [],
    page_loading: false,
    loading: false,
    currentdate: new Date().toLocaleString("en-US", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false, // Use 24-hour format
    }),
    showFilterTools: false,
    frommenu: false,
    tomenu: false,
    startdate: "",
    enddate: "",
    day1: "",
    breadcrumbs: [
      {
        sidebar_tilte: "home",
      },
      {
        text: "dashboard",
      },
    ],
    item_no: 0,
    ContentSeries: [],
    CourseVideoSeries: [],
    CourseStatusSeries: [],
    //labels: [],
    chartkey: 0,
    linedat: [],
    linedat1: [],
    chartOptions_Content: {
      chart: {
        type: "donut",
      },
      donut: {
        size: "20%",
      },
      labels: [],
      labels1: [],

      fill: {
        colors: [
          "#6ce5e8",
          "#41b8d6",
          "#2c8bb9",
          "#2e5f99",
          "#30356d",
          "#808080",
        ],
      },
      colors: [
        "#6ce5e8",
        "#41b8d6",
        "#2c8bb9",
        "#2e5f99",
        "#30356d",
        "#808080",
      ],
      grid: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
        position: "right", // Set label position to the top
        offsetX: -2,
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          // innerSize: 450,
          donut: {
            size: "40%",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function () {
            return "";
          },
          title: {
            formatter: function (value, series) {
              return (
                '<div style="text-align: center;">' +
                value.split(",")[0] +
                "<br>" +
                value.split(",")[1] +
                "<br>" +
                series.series[series.seriesIndex] +
                "%</div>"
              );
            },
          },
        },
      },
    },
    chartOptions: {
      chart: {
        type: "donut",
      },
      donut: {
        size: "20%",
      },
      labels: [],

      fill: {
        colors: [
          "#6ce5e8",
          "#41b8d6",
          "#2c8bb9",
          "#2e5f99",
          "#30356d",
          "#808080",
        ],
      },
      colors: [
        "#6ce5e8",
        "#41b8d6",
        "#2c8bb9",
        "#2e5f99",
        "#30356d",
        "#808080",
      ],
      grid: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
        position: "right", // Set label position to the top
        offsetX: -2,
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          // innerSize: 450,
          donut: {
            size: "40%",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function () {
            return "";
          },
          title: {
            formatter: function (seriesName, val) {
              return (
                '<div style="text-align: center;">' +
                seriesName +
                "<br>" +
                val.series[val.seriesIndex] +
                "%</div>"
              );
            },
          },
        },
      },
    },
    chartkeycourse: 0,
    chartOptions_Course: {
      chart: {
        type: "donut",
        width: 780,
      },

      labels: [],

      fill: {
        colors: ["#e31e17", "#f2c81f", "#A6CB3B", "#008AFF"],
      },
      colors: ["#e31e17", "#f2c81f", "#A6CB3B", "#008AFF"],
      grid: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
        position: "right", // Set label position to the top
        offsetX: -2,
      },
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          innerSize: 450,
          donut: {
            size: "45%",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function () {
            return "";
          },
          title: {
            formatter: function (seriesName, val) {
              return (
                '<div style="text-align: center;">' +
                seriesName +
                "<br>" +
                val.series[val.seriesIndex] +
                "%</div>"
              );
            },
          },
        },
      },
    },
    chartWidth: "100%",
    chartWidthline: "500px",
    chartHeight: "250px",
    chartHeights: "240px",
    chartHeightss: "250px",
    chartHeightBar: "235px",
    chartHeightCourse: "250px",
    series_Date: [],
    linechartOptions_Date: {
      chart: {
        height: 350,
        type: "line",
        toolbar: { show: false },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 5,
        hover: {
          size: 0, // Hide markers when hovering
        },
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: " ",
        align: "left",
        show: false,
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {},
      plotOptions: {
        bar: {
          columnWidth: "70%", // Adjust this value to control the width of the bars
          endingShape: "rounded",
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.round(value); // Round the value to the nearest integer
          },
        },
      },
    },
    series_Dept: [],
    chartOptionbar_Dept: {
      chart: {
        type: "bar",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
          endingShape: "rounded",
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      title: {
        text: "",
        align: "left",
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
      },
      legend: {
        show: true,
        position: "top",
        offsetY: -15,
        showForSingleSeries: true,
      },
      xaxis: {
        categories: [],
      },
      tooltip: {},
      yaxis: {
        labels: {
          formatter: function (value) {
            return Math.round(value); // Round the value to the nearest integer
          },
        },
      },
    },
    newdateoffset: new Date().getTimezoneOffset(),
    url:
      "EmployeeEXT/GetEmployeeExtAll?Id=" + localStorage.getItem("companyID"),
  }),
  mounted() {
    this.limitToDate();
    this.limitDate();
    this.StartViewDate();
    this.loadData();
    this.GetCourseMenu();
    this.setChartTitle();
  },
  computed: {
    headers() {
      return [
        { text: this.$t("no"), value: "No" },
        { text: this.$t("profile"), value: "profilePic" },
        { text: this.$t("employeeId"), value: "employeeID" },
        { text: this.$t("fullname"), value: "fullName" },
        { text: this.$t("group"), value: "group" },
        { text: this.$t("department"), value: "departmentName" },
        { text: this.$t("views/times"), value: "view" },
        { text: this.$t("viewtime"), value: "viewTime" },
      ];
    },
    minDate() {
      if (this.enddate) {
        const enddate = new Date(this.enddate);
        const minAllowedDate = new Date(
          enddate.getFullYear(),
          enddate.getMonth() - 3,
          enddate.getDate()
        );
        return minAllowedDate.toISOString().substr(0, 10);
      } else {
        return null;
      }
    },
  },
  watch: {
    "$i18n.locale"() {
      this.setChartTitle();
      this.chartkey += 1; // Change key to force re-render
      this.updateChartLabels();
    },
  },
  created() {
    this.setChartTitle();
    this.updateChartLabels;
  },
  methods: {
    updateChartLabels() {
      if (this.responseData && this.responseData.length > 0) {
        const translations = {
          delay: this.$t("lbldelay"),
          inprogress: this.$t("lblinprogress"),
          complete: this.$t("lblfinish"),
          notstarted: this.$t("lblnotstarted"),
        };
        this.chartOptions_Course.labels = this.responseData.map(
          (v) => translations[v.status] || ""
        );
        this.chartkeycourse++;
      }
    },
    setChartTitle() {
      this.chartOptionbar_Dept.title.text = this.$t("views");
      this.linechartOptions_Date.title.text = this.$t("views");
    },
    // getLastSevenDays() {
    //   const dates = [];
    //   for (let i = 6; i >= 0; i--) {
    //     const date = new Date();
    //     date.setDate(date.getDate() - i);
    //     dates.push(date.toLocaleDateString("dd/MM/yy"));
    //   }
    //   return dates;
    // },
    allowedEndDates(val) {
      return val >= this.startdate;
    },

    limitToDate() {
      if (this.currentdate) {
        this.day1 = new Date(this.currentdate);
        this.enddate = new Date(this.currentdate);
        this.enddate = this.enddate.toISOString().substr(0, 10);
      }
    },
    limitDate() {
      if (this.currentdate) {
        this.day1 = new Date(this.currentdate);
        this.startdate = new Date(this.currentdate);

        this.startdate.setDate(this.startdate.getDate() - 6);
        this.startdate = this.startdate.toISOString().substr(0, 10);
      }
    },
    FilterData() {
      this.CourseVideoSeries = [];
      this.CourseStatusSeries = [];
      this.ContentSeries = [];
      this.chartOptions.labels = [];
      this.chartOptions_Course.labels = [];
      this.chartOptions_Course.labels = [];
      let checkPermission = this.check_Permissions("dashboard");
      if (!checkPermission) {
        this.permissionDialog = true;
        this.permissionMessage =
          "You don't have permission to choose Date to filter data.";
      } else {
        this.loadData();
        this.GetCourseMenu();
      }
    },
    StartViewDate() {
      if (this.startdate) {
        this.startdate = new Date(this.startdate);
        this.startdate = this.startdate.toISOString().substr(0, 10);
        if (this.startdate > this.enddate) {
          this.enddate = this.startdate;
        }
      }
      this.frommenu = false;
    },

    async loadData() {
      try {
        this.page_loading = true;
        await Promise.all([
          this.GetDashboardReport(),
          this.GetTopFiveViewContent(),
          this.GetContentMostView(),
          this.GetDepartmentMostContentViewers(),
          this.GetTopFiveViewContentVideo(),
        ]);
        this.page_loading = false;
        this.loading = false;
      } catch (error) {
        console.error("Error loading data:", error);
      }
    },

    async GetDashboardReport() {
      let that = this;
      let request = {
        companyID: localStorage.getItem("companyID"),
        viewDateFrom: that.startdate + " 00:00",
        viewDateTo: that.enddate + " 23:59",
        userID: [],
        departmentID: [],
        offset: that.newdateoffset,
      };

      await axios
        .post(`${that.web_url}Dashboard/GetAllDashboardUserDataReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.item = response.data.data;
            that.TopFiveUserList =
              response.data.data.topFiveContentViewerEmp != null
                ? response.data.data.topFiveContentViewerEmp
                : [];

            that.clear = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetTopFiveViewContent() {
      let that = this;

      let request = {
        companyID: localStorage.getItem("companyID"),
        viewDateFrom: that.startdate + " 00:00",
        viewDateTo: that.enddate + " 23:59",
        userID: [],
        departmentID: [],
        offset: that.newdateoffset,
      };

      await axios
        .post(
          `${that.web_url}Dashboard/GetDashboardFiveMostViewedContent`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            if (response.data.data.length > 0) {
              let lastIndex = response.data.data.length;
              let response_temp =
                response.data.data[lastIndex - 1].percent === 0
                  ? response.data.data.slice(0, -1)
                  : response.data.data;

              that.ContentSeries = response_temp.map((v) => v.percent);

              switch (true) {
                case window.innerWidth < 2002:
                  // Case 1: Inner width is less than 2043 pixels
                  that.chartOptions_Content.labels = response_temp.map((v) => [
                    String(
                      v.title.length > 10
                        ? v.title.substring(0, 9) + "..."
                        : v.title
                    ),
                    "(" + String(v.type) + ")",
                  ]);
                  break;

                case window.innerWidth < 2903:
                  that.chartOptions_Content.labels = response_temp.map((v) => [
                    String(
                      v.title.length > 30
                        ? v.title.substring(0, 30) + "..."
                        : v.title
                    ),
                    "(" + String(v.type) + ")",
                  ]);
                  break;
                default:
                  // Default case, in case none of the conditions match
                  break;
              }

              that.chartkey++;
            }
            that.clear = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetTopFiveViewContentVideo() {
      let that = this;

      let request = {
        companyID: localStorage.getItem("companyID"),
        viewDateFrom: that.startdate + " 00:00",
        viewDateTo: that.enddate + " 23:59",
        userID: [],
        departmentID: [],
        offset: that.newdateoffset,
      };

      await axios
        .post(
          `${that.web_url}Dashboard/GetDashboardFiveMostViewedCourseVideo`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            if (response.data.data.length > 0) {
              let lastIndex = response.data.data.length;
              let response_temp =
                response.data.data[lastIndex - 1].percent === 0
                  ? response.data.data.slice(0, -1)
                  : response.data.data;

              that.CourseVideoSeries = response_temp.map(
                (v) =>
                  // parseInt(v.percent, 10)
                  v.percent
              );

              if (window.innerWidth <= 2000) {
                that.chartOptions.labels = response_temp.map((v) =>
                  String(
                    v.title.length > 15
                      ? v.title.substring(0, 15) + "..."
                      : v.title
                  )
                );
              } else {
                that.chartOptions.labels = response_temp.map((v) =>
                  String(
                    v.title.length > 45
                      ? v.title.substring(0, 45) + "..."
                      : v.title
                  )
                );
              }
              that.chartkey++;
            }
            that.clear = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetCourseMenu() {
      let that = this;
      await axios
        .post(
          `${that.web_url}Dashboard/GetStatusOftheCompanyCourseMenu?ComapanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            if (response.data.data.length > 0) {
              that.responseData = response.data.data; // Store response data
              that.CourseStatusSeries = that.responseData.map((v) => v.percent);

              const translations = {
                delay: that.$t("lbldelay"),
                inprogress: that.$t("lblinprogress"),
                complete: that.$t("lblfinish"),
                notstarted: that.$t("lblnotstarted"),
              };

              that.chartOptions_Course.labels = that.responseData.map(
                (v) => translations[v.status] || ""
              );
              // .then(function (response) {
              //   if (response.data.status == 0) {
              //     if (response.data.data.length > 0) {
              //       that.CourseStatusSeries = response.data.data.map(
              //         (v) =>
              //           //  parseInt(v.percent, 10)
              //           v.percent
              //       );

              //       that.chartOptions_Course.labels = response.data.data.map(
              //         (v) => {
              //           switch (v.status) {
              //             case "delay":
              //               return that.$t("lbldelay");
              //             case "inprogress":
              //               return that.$t("lblinprogress");
              //             case "complete":
              //               return that.$t("lblfinish");
              //             case "notstarted":
              //               return that.$t("lblnotstarted");
              //             default:
              //               return "";
              //           }
              //         }
              //         // String(v.status)
              //       );

              that.chartkeycourse++;
            }
            that.clear = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetContentMostView() {
      let that = this;

      let request = {
        companyID: localStorage.getItem("companyID"),
        viewDateFrom: that.startdate + " 00:00",
        viewDateTo: that.enddate + " 23:59",
        userID: [],
        departmentID: [],
        offset: that.newdateoffset,
      };

      await axios
        .post(`${that.web_url}Dashboard/GetContentMostViewedDate`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            if (
              response.data.data.detail.length != 0 &&
              response.data.data.dateList.length != 0
            ) {
              that.series_Date = [
                {
                  name: "View Count",
                  data: response.data.data.detail.map((item) => ({
                    y: item.viewCount,
                    x: item.viewDate, // Assuming viewDate is the date value
                  })),
                },
              ];

              that.linechartOptions_Date.xaxis.categories =
                response.data.data.dateList.map((v) => v.viewDate);
              that.chartkey++;
              that.linechartOptions_Date.tooltip.custom = function ({
                series,
                seriesIndex,
                dataPointIndex,
              }) {
                const dataPoint = series[seriesIndex][dataPointIndex];
                const viewDate =
                  that.linechartOptions_Date.xaxis.categories[dataPointIndex];
                const viewCount = dataPoint;
                return `<div class="custom-tooltip">                
                        <div class="tooltip-body">
                          <div>${viewDate}</div>
                          <div>${viewCount} view</div>
                        </div>
                        </div>  `;
              };
            }
            that.clear = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetDepartmentMostContentViewers() {
      let that = this;

      let request = {
        companyID: localStorage.getItem("companyID"),
        viewDateFrom: that.startdate + " 00:00",
        viewDateTo: that.enddate + " 23:59",
        userID: [],
        departmentID: [],
        offset: that.newdateoffset,
      };

      await axios
        .post(
          `${that.web_url}Dashboard/GetDepartmentWithMostContentViewers`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            if (
              response.data.data.departmentList != null &&
              response.data.data.dateList != null
            ) {
              that.series_Dept = response.data.data.departmentList.map(
                (department) => ({
                  name: department.departmentName,
                  data: department.detail.map(
                    (detailItem) =>
                      // parseInt(detailItem.viewCount, 10)
                      detailItem.viewCount
                  ),
                })
              );

              that.chartOptionbar_Dept.xaxis.categories =
                response.data.data.dateList.map((v) => v.viewDate);
              that.chartkey++;

              that.chartOptionbar_Dept.tooltip.custom = function ({
                series,
                seriesIndex,
                dataPointIndex,
                w,
              }) {
                const viewDate = w.globals.labels[dataPointIndex]; // labels represent viewDate
                const viewCount = series[seriesIndex][dataPointIndex]; // viewCount is the data point

                return `
            <div class="custom-tooltip">                
                <div class="tooltip-body">
                    <div>${viewDate}</div>
                    <div>${viewCount} view</div>
                </div>
            </div>
        `;
              };
            }
          }
          that.clear = false;
        })
        .catch(function (err) {
          throw err;
        });
    },
  },
};
</script>
<style scoped>
#date_ {
  left: 1812px !important;
  transform-origin: right top !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
.tabledata {
  padding-left: 10px;
  width: 99%;
  height: 351px;
}
::v-deep .profile-image {
  border-radius: 50%;
  border: 2px solid #ffffff; /* Add a border if needed */
}
.white-text {
  color: white;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
.v-menu__content.theme--light.menuable__content__active {
  min-width: auto !important;
  top: 163px !important;
  left: 191px !important;
  transform-origin: left top !important;
  z-index: 8 !important;
}
@media (max-width: 1264px) {
  .v-menu__content.theme--light.menuable__content__active {
    left: auto !important; /* Adjust as needed for medium screens */
    right: 10px !important; /* Set the right position for medium screens */
  }
}
@media (min-width: 1440px) {
  .v-menu__content.theme--light.menuable__content__active {
    left: auto !important;
    right: 255px !important; /* Adjust as needed for desktop screens */
  }
}
.dynamic-height-chart {
  width: 100%;
  /* Set a default height for smaller screens or laptops */
  height: 300px;
}
@media (min-width: 1200px) {
  .dynamic-height-chart {
    /* Set a different height for larger screens */
    height: 250px;
  }
}
@media (min-width: 1200px) {
  .dynamic-height-charts {
    /* Set a different height for larger screens */
    height: 350px;
  }
}
.user-counts {
  max-width: 100px;
  font-size: 28px;
  text-align: center;
  position: absolute;
  top: 21%;
  left: 10%;
}
.user-countsactive {
  max-width: 100px;
  font-size: 28px;
  text-align: center;
  position: absolute;
  top: 21%;
  left: 10%;
}
.totalusercount {
  text-align: center;
  max-width: 185px;
  top: 5%;
  left: 9%;
  position: absolute;
}
@media screen and (min-width: 2350px) and (max-width: 2700px) {
  .totalusercount {
    text-align: center;
    max-width: 185px;
    top: 5%;
    left: 28%; /* Adjusted to match the default value */
    position: absolute;
  }
}
@media screen and (min-width: 1718px) {
  .user-counts {
    max-width: 100px;
    font-size: 28px;
    text-align: center;
    position: absolute;
    top: 21%;
    left: 10%;
  }
}
.my-custom-cardall {
  width: 100%;
  height: 96%;
  min-height: 90px;

  background-size: auto;
  border-radius: 6px;
  background-position: center;
  position: relative;
}
.my-custom-cardall::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #ff8a00;
}

.my-custom-cardactive {
  width: 100%;
  height: 96%;
  min-height: 90px;

  background-size: auto;
  border-radius: 6px !important;
  background-position: center;
  position: relative;
}
.my-custom-cardactive::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #a6cc39;
}

.my-custom-cardinactive {
  width: 100%;
  height: 96%;
  min-height: 90px;

  background-size: auto;
  border-radius: 6px !important;
  background-position: center;
  position: relative;
}
.my-custom-cardinactive::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #ff3d3d;
}

.my-custom-cards {
  width: 400px;
  height: 330px;
  border-radius: 6px !important;
  overflow: hidden;
  background-color: #3c3c3c;
  background-size: auto;
  background-position: center;
  position: relative;
}

@media screen and (min-width: 1718px) {
  .my-custom-cardall {
    width: 100%;
    height: 96%;
    min-height: 90px;

    border-radius: 6px !important;
    background-position: center;
    position: relative;
  }
  .my-custom-cardall::before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    width: 91%;
    height: 100%;
    border-radius: 6px;
  }
  .my-custom-cardactive {
    width: 100%;
    height: 96%;
    min-height: 90px;

    border-radius: 6px !important;
    background-position: center;
    position: relative;
  }
  .my-custom-cardactive::before {
    content: "";
    position: absolute;
    top: 0;
    left: 4%;
    width: 92%;
    height: 100%;
    border-radius: 6px;
    background-color: #a6cc39;
  }
  .my-custom-cardinactive {
    width: 100%;
    height: 96%;
    min-height: 90px;

    border-radius: 6px !important;
    background-position: center;
    position: relative;
  }
  .my-custom-cardinactive::before {
    content: "";
    position: absolute;
    top: 0;
    left: 4%;
    width: 92%;
    height: 100%;
    border-radius: 6px;
    background-color: #ff3d3d;
  }
  .my-custom-cards {
    width: 400px;
    height: 330px;
    border-radius: 6px !important;
    overflow: hidden;
    background-color: #3c3c3c;
    background-position: center;
    position: relative;
  }
  .my-custom-cards::before {
    content: "";
    position: absolute;
    top: 0;
    left: 1.3%;
    width: 97%;
    height: 100%;
    border-radius: 6px;
    background-color: #3c3c3c;
  }
}
</style>
<style scoped>
::v-deep .custom-tooltip {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}
::v-deep .tooltip-body div {
  margin-bottom: 5px;
}
</style>
